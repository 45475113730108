<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M512,256c0,141.387-114.613,256-256,256c-26.184,0-51.458-3.935-75.255-11.237
	c-37.503-11.52-71.343-31.43-99.323-57.531C31.324,396.513,0,329.919,0,256C0,114.613,114.613,0,256,0
	c11.083,0,22.001,0.709,32.718,2.068c22.946,2.93,44.947,8.897,65.577,17.475C446.895,58.086,512,149.446,512,256z"
    />
    <path
      style="fill:#FFE4A9;"
      d="M512,256c0,141.387-114.613,256-256,256c-26.184,0-51.458-3.935-75.255-11.237l173.55-481.22
	C446.895,58.086,512,149.446,512,256z"
    />
    <path
      style="fill:#F6C454;"
      d="M339.056,231.97c0.594-2.78,0.913-5.662,0.913-8.618v-55.775c0-22.715-18.48-41.194-41.193-41.194
	h-2.592V91.184h-35.461v35.198h-19.041V91.184H206.22v35.198h-14.446h-41.372v35.461h23.64v84.971v100.469h-23.64v35.461h41.37
	h14.446v38.072h35.461v-38.072h19.041v38.072h35.461v-38.072h27.755c22.713,0,41.193-18.479,41.193-41.193v-71.274
	C365.134,252.9,354.311,238.012,339.056,231.97z M296.184,161.845h2.592c3.161,0,5.732,2.571,5.732,5.733v55.775
	c0,3.161-2.571,5.732-5.732,5.732h-89.273v-67.24H296.184z M329.672,341.552c0,3.161-2.571,5.732-5.732,5.732H209.505v-82.739
	h89.273h25.164c3.161,0,5.732,2.571,5.732,5.733v71.274H329.672z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
